import React, { useState } from 'react'
// import Welcome from '../Welcome/Welcome'
import Footer from '../Footer/Footer'
import Testimonial2 from '../Testimonial/Testimonial2'
import BannerEvent from '../BannerEvent/BannerEvent'
// import EventWelcome from '../EventWelcome/EventWelcome'
import chair from "../../assets/banner1.webp"
import EventJson from "../EventData/EventData"
import './event.css'
import { Link, useLocation} from 'react-router-dom'
import MetaTags from 'react-meta-tags';

 
function Event({content}) {

  const search = useLocation()
  const path = search.pathname;
  const [serviceData,setServiceData]= useState(EventJson)
  console.log(path)
  const EventsF =  serviceData.find((e)=> e.slug === path)
  return (
    <>
    <MetaTags>
      <title>{EventsF.title}</title>
        <meta title={EventsF.title} />
        <meta
          name="description"
          content={EventsF.discription}
        />
        <meta
          name="keywords"
          content="Best Event Management Comapnay in Andheri,Corporate Event Organisers in Andheri,Top Event Organisers in Andheri | WYND EVENTS,Top Event Organisers in Andheri,Social Event Organisers in Andheri,Event Management Company near Andheri,Top Corporate Event Management Companies in Andheri,Birthday Party Organisers in Andheri"
        />
        <link rel="canonical" href="https://wyndevents.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={EventsF.title} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wyndevents.in/"/>
        <meta property="og:description" content={EventsF.discription} />
        <meta property="og:image" content="https://wyndevents.in/static/media/wyndLogo.5947b07ac9491c4f6a5b.png" />       
      </MetaTags>


    <BannerEvent name={EventsF.h1}/>

<section id="about" className="pt-14 pb-14">
  <div className="container">
    <div
      className="row align-items-start align-items-md-stretch"
      data-cues="fadeIn"
      data-disabled="true"
    >
     
        <div
          className="react-reveal row g-0"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-1"
          }}
        >
              <div
        className=" col-lg-6"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal bg-white h-100 d-flex align-items-center"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-2"
          }}
        >
          <div className="p-lg-5 mb-8">
            {/* <Fade left > */}
            <span className="mb-2 fw-medium text-secondary ff-sub text-uppercase ls-1 d-block">

            </span>
            <h2 className=" ff-heading">{EventsF.eventName}</h2>
            <p className="mb-6">
              {EventsF.Content}
            </p>
           {/* <Link to='/aboutus'>
           <a role="button" tabIndex={0} href="#!" className="custom-btn "> 
            <a href="/wedding-event-planner-mumbai" style={{ color: "white" }}>
              Read More
            </a>
            </a></Link> */}
            {/* </Fade> */}
          </div>
        </div>
      
        </div>


{/* *******************************IMAGE**************************************************** */}
         
         
          <div className="col-6 events-img">
            <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
            
            {/* <Fade left> */}
               <img
                src={EventsF.imageRight}
               
                className="img-fluid w-100 rounded shadow-sm"
                alt=""
              />
              {/* </Fade> */}
            </figure>
          </div>
        
      
      </div>
    
    </div>
  </div>
</section>


  )
     
      <Footer/>
    </>
  )
}

export default Event
