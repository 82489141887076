
import  React,{ useRef, useLayoutEffect, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
 import lightGallery from "lightgallery";
import lightImage from '../../assets/room4.jpg'
import lightImage1 from '../../assets/room3.jpeg'
import lightImage2 from '../../assets/room2.jpg'
import lightImage3 from '../../assets/conference3.webp'
import lightImage4 from '../../assets/banquet2.webp'
import lightImage5 from '../../assets/room4.jpg'
import img1 from "../../assets/imageWebp/wynd(1).webp"
import img2 from "../../assets/imageWebp/wynd(2).webp"
import img3 from "../../assets/imageWebp/wynd(10).jpg"
import img4 from "../../assets/imageWebp/wynd(11).jpg"
import img5 from "../../assets/imageWebp/wynd(13).jpg"
import img6 from "../../assets/imageWebp/wynd(12).jpg"


function MainGallery() {

  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <>
      <div className="mb-5" style={{paddingTop:"20px"}}>
<div className="container">
<div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2">Gallery</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
      Flawlessly executed dream events.
      </p>
    </div>
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              src={img1}
              data-lg-size="1600-2400"
              data-lg-src={img1}
            >
              <img
               src={img1}
                style={{ height: "300px", width: "100%" }}
                alt="best-event-management-company-andheri"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              src={img2}
              data-lg-size="1600-2400"
              data-lg-src={img2}            >
              <img
               src={img2}
                style={{ height: "300px", width: "100%" }}
                alt="company-andheri"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              src={img3}
              data-lg-size="1600-2400"
              data-lg-src={img3}            >
              <img
               src={img3}
                style={{ height: "300px", width: "100%" }}
                alt="company-andheri"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              src={img4}
              data-lg-size="1600-2400"
              data-lg-src={img4}            >
              <img
                src={img4}
                style={{ height: "300px", width: "100%" }}
                alt="best-event-management-andheri"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              src={img5}
              data-lg-size="1600-2400"
              data-lg-src={img5}            >
              <img
                src={img5}
                style={{ height: "300px", width: "100%" }}
                alt="best-event-management-company-andheri"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              src={img6}
              data-lg-size="1600-2400"
              data-lg-src={img6}            >
              <img
                src={img6}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
          </div>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
                <Link to='/social-event-organisers-andheri'>
              <a
                href="/social-event-organisers-andheri"
                style={{ color: "white" }}
              >
                
                View More
                
              
              </a>
              </Link>
            </button>
          </div>
        </div>
</div>
    </>
  )
}

export default MainGallery
