import React, { useState } from 'react'
import aboutBan from '../../assets/christian-wedding-in-kerala.jpg'
import banquentData from "../BanquetJson/JsonBanquet2"
import { Link, useLocation } from 'react-router-dom'
function BanquetGallery() {
  const search = useLocation()
  // const path = search.pathname;
  // const [serviceData,setServiceData]= useState(banquentData)
  // console.log(path)
  // const EventsF =  serviceData.find((e)=> e.slug === path)
  //  console.log(EventsF)
  return (
   
    <>
      <section
  id="breadcrumb-section"
  className="breadcrumb-section clearfix margintop"
>
  <div
    className="jarallax"
    style={{
      backgroundImage:
      `url(${aboutBan})`,
      backgroundPosition:'100% 100%',
      backgroundSize: "cover",
      zIndex: 0
    }}
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title"></span>
              <h2 className="big-title">
                <strong>Banquet </strong>
              </h2>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <Link to="/" className="breadcrumb-link">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to="/best-banquet-andheri">
                  Banquet
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            'url("/static/media/banner1.c5114dadfde31d8f7663.jpg")',
          position: "fixed",
          top: 0,
          left: 0,
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px"
        }}
      />
    </div>
  </div>
</section>

    </>
  )
}

export default BanquetGallery

