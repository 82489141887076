import React from 'react'
import About from '../Components/About/About'
import MetaTags from 'react-meta-tags';


function AboutUs() {
  return (
    <div>
      <MetaTags>
      <title>Corporate Event Organisers in Andheri | WYND EVENTS</title>
        <meta title="Corporate Event Organisers in Andheri | WYND EVENTS" />
        <meta
          name="description"
          content="Discover top-tier Corporate Event Organisers in Andheri dedicated to curating unparalleled experiences. From flawless planning to seamless execution."
        />
        <meta
          name="keywords"
          content="Best Event Management Comapnay in Andheri,Corporate Event Organisers in Andheri,Best Banquet in Andheri,Top Event Organisers in Andheri,Social Event Organisers in Andheri,Event Management Company near Andheri,Top Corporate Event Management Companies in Andheri,Birthday Party Organisers in Andheri"
        />
        <link rel="canonical" href="https://wyndevents.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Corporate Event Organisers in Andheri | WYND EVENTS" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wyndevents.in/"/>
        <meta property="og:description" content="Discover top-tier Corporate Event Organisers in Andheri dedicated to curating unparalleled experiences. From flawless planning to seamless execution." />
        <meta property="og:image" content="https://wyndevents.in/static/media/wyndLogo.5947b07ac9491c4f6a5b.png" />       
      </MetaTags>
      <About/>
      
    </div>
  )
}

export default AboutUs
