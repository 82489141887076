import React from 'react'
 import Contact from '../Components/Contact/Contact'
 import MetaTags from 'react-meta-tags';
function ContectUs() {
  return (
    <>
     <MetaTags>
      <title>Event Management Company near Andheri  | WYND EVENTS</title>
        <meta title="Event Management Company near Andheri  | WYND EVENTS" />
        <meta
          name="description"
          content="Unlock unparalleled event experiences with the leading Event Management Company near Andheri. From meticulous planning to flawless execution."
        />
        <meta
          name="keywords"
          content="Best Event Management Comapnay in Andheri,Corporate Event Organisers in Andheri,Event Management Company near Andheri  | WYND EVENTS,Top Event Organisers in Andheri,Social Event Organisers in Andheri,Event Management Company near Andheri,Top Corporate Event Management Companies in Andheri,Birthday Party Organisers in Andheri"
        />
        <link rel="canonical" href="https://wyndevents.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Event Management Company near Andheri  | WYND EVENTS" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wyndevents.in/"/>
        <meta property="og:description" content="Unlock unparalleled event experiences with the leading Event Management Company near Andheri. From meticulous planning to flawless execution." />
        <meta property="og:image" content="https://wyndevents.in/static/media/wyndLogo.5947b07ac9491c4f6a5b.png" />       
      </MetaTags>
      <Contact/>
    </>
  )
}

export default ContectUs
