import React from 'react'

import Welcome from '../Welcome/Welcome'
import Service from '../Service/Service'
import Testimonial2 from '../Testimonial/Testimonial2'
import Footer from '../Footer/Footer'
// import Amenity from '../Amenity/Amenity'
 import Banner from '../Banner/Banner'
import Peramount from '../Gallery/Peramount'
import MainGallery from '../MainGallery/MainGallery'
import AboutCount from '../AboutCount/AboutCount'
import MetaTags from 'react-meta-tags';


function MainHome() {
  return (
    <> 

      <MetaTags>
      <title>Best Event Management Comapnay in Andheri | WYND EVENTS</title>
        <meta title="Best Event Management Comapnay in Andheri | WYND EVENTS" />
        <meta
          name="description"
          content="Discover unparalleled event planning and execution with the Best Event Management Company in Andheri. Our expert team ensures seamless experiences."
        />
        <meta
          name="keywords"
          content="Best Event Management Comapnay in Andheri,Corporate Event Organisers in Andheri,Best Banquet in Andheri,Top Event Organisers in Andheri,Social Event Organisers in Andheri,Event Management Company near Andheri,Top Corporate Event Management Companies in Andheri,Birthday Party Organisers in Andheri"
        />
        <link rel="canonical" href="https://wyndevents.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Event Management Comapnay in Andheri | WYND EVENTS" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wyndevents.in/"/>
        <meta property="og:description" content="Discover unparalleled event planning and execution with the Best Event Management Company in Andheri. Our expert team ensures seamless experiences." />
        <meta property="og:image" content="https://wyndevents.in/static/media/wyndLogo.5947b07ac9491c4f6a5b.png" />       
      </MetaTags>
      <Banner/>
      <Welcome/>
      <Service/>
    
      <MainGallery />
      {/* <Peramount/> */}
      <AboutCount />
      <Testimonial2/>
      <Footer/>
    </>
  )
}

export default MainHome
