import React, { useState, useEffect } from "react";
import "../NavBar/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import EventData from '../EventData/EventData';
import Logo from "../../assets/wyndLogo.png";
import JsonBanquet2 from "../BanquetJson/JsonBanquet2";

const NavBar = () => {
  const [color, setColor] = useState(false);
  const className = "dropdownShow";
  // const textColor = "text-black";
  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 78) {
        setColor(true);
      } else {
        setColor(false);
      }
    };
    window.addEventListener("scroll", changeColor);
    
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  return (
    <div>
      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} alt="best-event-management-company-andheri" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                  <Nav.Link href="/">
                    <Link className={color ? "text-black" : "text-white"} to="/">Home</Link>
                  </Nav.Link>
                  <Nav.Link href="/AboutUs">
                    <Link className={color ? "text-black" : "text-white"}  to="/corporate-event-organisers-andheri">About Us</Link>
                  </Nav.Link>
                  <Nav.Link href="/Banquet">
                    <Link className={color ? "text-black" : "text-white"}  to="/best-banquet-andheri">Banquet</Link>
                  </Nav.Link>
                  {/* <button className="dropdownShow">
                    Banquets
                    <div className="dorpdown">
                    <div className="dropdown_list">
                                         
                                         {JsonBanquet2.map((elm,i)=>{
                                             const { h1 , slug} =elm
                                             return(
                                                 <>
                                                 <Nav.Link href="/Banquet">

                                                     <Link to="/Banquet" >Banquet</Link>

                                                 </Nav.Link>
                                                 </>
                                             )
                                         })}
                                       
                                     </div>
                    </div>
                  </button> */}
                  <Nav.Link href="/">
                    <Link className={color ? "text-black" : "text-white"}  to="/social-event-organisers-andheri">Gallery</Link>
                  </Nav.Link>
                  <button className={`${className}  ${color ? "text-black" : "text-white"}`}>
                    Events
                    <div className="dorpdown">
                      <div className="dropdown_list">
                        {EventData.map((v,i)=>{
                          return(
                            <>
                             <Nav.Link href={v.slug}>
                          <Link to={v.slug}>{v.h1}</Link>
                        </Nav.Link>
                            </>
                          )
                        })}
                        {/* <Nav.Link href="/Greenfarm">
                          <Link to="/Greenfarm">Birthday</Link>
                        </Nav.Link>
                        <Nav.Link href="Royalfarm">
                          <Link to="Royalfarm">Wedding</Link>
                        </Nav.Link> */}
                      </div>
                    </div>
                  </button>
                  {/* <Nav.Link href="/home-care-nursing-services">
                      <Link to="/home-care-nursing-services">Gallery</Link>
                    </Nav.Link> */}
                  <Nav.Link href="/ContectUs">
                    <Link className={color ? "text-black" : "text-white"} to="/event-management-near-andheri">Contact Us</Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
