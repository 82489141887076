import React, {  useLayoutEffect } from 'react'
import banquet1 from '../../assets/room3.jpeg'
import Footer from '../Footer/Footer'
import Welcome from '../Welcome/Welcome'
import BanquetGallery from '../BanquetGallery/BanquetGallery'
import { useLocation } from 'react-router-dom'
import banquentData from "../BanquetJson/JsonBanquet2"
import BanquetGallary from '../BanquetBoxLight/BanquetGallary'
import WelcomeBanquet from '../WelcomeBanquet/WelcomeBanquet'

function Banquet() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  // const search = useLocation()
  // const path = search.pathname;
  // const [serviceData,setServiceData]= useState(banquentData)
  // console.log(path)
  // const EventsF =  serviceData.find((e)=> e.slug === path)
  //  console.log(EventsF)
   
  return (
  
    <>
    <BanquetGallery/>
    <WelcomeBanquet />
    {/* <Welcome/> */}
      <section id="facilitiesAmenities" className="pt-14 pb-10">
  <div className="container">
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2">Banquet</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
        {/* {EventsF.h3} */}
      </p>
    </div>

   
    <BanquetGallary/>
    
    
  </div>
</section>
<Footer/>
  
    </>
  // ))
  )
}

export default Banquet
