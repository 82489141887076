
import './App.css'
import MainHome from './Components/Main_Home/MainHome'
// import 'bootstrap/dist/css/bootstrap.css';
import Hadder from './Components/NavBar/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './Pages/AboutUs';
import ContectUs from './Pages/ContectUs';
import Gallery from './Pages/Gallery.jsx';
import banquentData from './Components/BanquetJson/JsonBanquet2.jsx'
import Banquet from './Components/Banquet/Banquet.jsx';
import EventData from './Components/EventData/EventData.jsx';
import Event from './Components/Event/Event.jsx';
import ErrorPage from './Components/ErrorPage/ErrorPage.jsx';
// import 'bootstrap/dist/css/bootstrap.min.css';


function App() {


  return (
    <>
  
  <BrowserRouter> 
      <Hadder/>
      <Routes>

     <Route path='/' element={<MainHome/>}></Route>
     <Route path='/social-event-organisers-andheri' element={<Gallery/>}></Route>
     <Route path='/corporate-event-organisers-andheri' element={<AboutUs/>}></Route>
     <Route path='/event-management-near-andheri' element={<ContectUs/>}></Route>
     <Route path="/best-banquet-andheri" element={<Banquet />} ></Route>
     <Route path='*' element={<ErrorPage/>}/>
     {/* {banquentData.map((v,i)=>{
      // console.log(v.slug)
      return(<>
      <Route 
      key={i}
      exact
      path={v.slug}
      element={<Banquet />}
      ></Route> 
      </>)
     })} */}


     
     {EventData.map((v,i)=>{
      // console.log(v.slug)
      return(<>
      <Route 
      key={i}
      exact
      path={v.slug}
      element={<Event/>}
      ></Route> 
      </>)
     })}
     </Routes>
     </BrowserRouter>
    
   
   
    </>
  )
}

export default App
