import React from 'react'
import './AboutCss.css'
import iconOne from '../../assets/icon-1.png'
import iconTwo from '../../assets/icon-2.png'
import iconThree from '../../assets/icon3.png'
import customerIcon from '../../assets/customerIcon.png'
function AboutCount() {
  return (
    <>
      <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img11 clearfix">
  <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
  <div className="container">
    <div className="row ttm-fid-row-wrapper">
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{ textAlign: "center" }}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
                <i className="flaticon-haircut">
                  <img src={iconOne}/>
                </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={15}
                data-interval={1}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                15
              </span>
            </h4>
            <h3 className="ttm-fid-title textcenterje">Years Of Experience</h3>
          </div>
         
        </div>
      </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{ textAlign: "center" }}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
                <i className="flaticon-haircut">
                  <img src={iconTwo} />
                </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={320}
                data-interval={10}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                320
              </span>
            </h4>
            <h3 className="ttm-fid-title textcenterje">Field Expert</h3>
          </div>
          
        </div>
      </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{ textAlign: "center" }}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
                <i className="flaticon-salon">
                  <img src={iconThree} />
                </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={45}
                data-interval={5}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                45
              </span>
            </h4>
            <h3 className="ttm-fid-title textcenterje">Co-operate</h3>
          </div>
         
        </div>
      </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{ textAlign: "center" }}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
                <i className="flaticon-barber">
                  <img src={customerIcon} />
                </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={2555}
                data-interval={100}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                2555
              </span>
            </h4>
            <h3 className="ttm-fid-title textcenterje">Customers Winner</h3>
          </div>
          
        </div>
      </div>
    </div>
    <div className="row">
      <div className="featured-icon-box icon-align-before-content style7 mt-50 ml-15 res-991-mt-30 res-767-mt-10">
        <div className="featured-icon">
          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-bgcolor-white ttm-icon_element-style-rounded ttm-icon_element-size-sm">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              color="#a3227ecf"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z" />
            </svg>
          </div>
        </div>
        <div className="featured-content ">
          <div className="featured-title">
            <h3 className="ttm-textcolor-white " style={{ color: "white" }}>
              <span style={{ color: "white" }}> First Step Call Now !</span>
            </h3>
          </div>
          <div className="featured-desc">
            <p>Phone : 9167638955</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default AboutCount

