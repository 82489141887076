import React, { useState,useRef } from "react";
import './ContactMap.css'
import $ from "jquery";

function ContactMap(props) {
   // email
   const formRef = useRef(null);
   const [custName, setcustName] = useState("");
   const [custContact, setCustContact] = useState("");
   const [custMessage, setCustMessage] = useState("");
   const [custEmail, setCustEmail] = useState("");
   const [custSubject, setCustSubject] = useState("");
   const [formError, setFormError] = useState(false);
 
   const ContactEnquiry = (e) => {
     e.preventDefault();
 
     // Validate form fields
     if (!custName || !custContact || !custMessage || !custEmail ) {
       setFormError(true);
       return;
     }
 
     // Reset form error state
     setFormError(false);
 
     // Your form submission logic goes here
     var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Wynd Events Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Wynd Events</p></div></body></html>`;
 
     $.post(
       "https://skdm.in/server/v1/send_lead_mail.php",
       {
         toEmail: "wyndhotels@gmail.com",
         fromEmail: "skdmlead@gmail.com",
         bccMail: "skdmlead@gmail.com",
         mailSubject: "New Customer Lead",
         mailBody: body,
         accountName: "WindEvent",
         accountLeadSource: "https://skdm.in",
         accountLeadName: custName,
       },
       function (dataa, status) {
         console.log("data :" + dataa);
       }
     );
 
     // Reset the form
     if (formRef.current) {
       formRef.current.reset();
     }
 
     alert("Your Form has Submitted. Our team will contact you soon.");
 
   };
 
  
  return (
    <>
      <section className="ContactUs-form-Section-4">
  <div className="container">
    <div className="row">
      <div className="col-md-5 col-sm-12">
        <div className="contact-form-image">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.835700869388!2d72.88563467497836!3d19.114862382097776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d5b614e4e3%3A0xac6c44ff70802530!2sWynd%20Banquet!5e0!3m2!1sen!2sin!4v1707731402322!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="col-md-7 col-sm-12">
        <form
        method="post"
        ref={formRef}
        action=""
        id="contact-form">
        <div className="Name-input">
          <div className="row">
            <div className="col">
              <input
                type="text"
                className="form-control custName"
                placeholder="First name"
                onChange={(e) => setcustName(e.target.value)}

              />
            </div>
          </div>
        </div>
        <div className="Email">
          <input
            type="email"
            className="form-control custEmail"
            placeholder="Email"
            onChange={(e) => setCustEmail(e.target.value)}

          />
        </div>
        <div className="Phone-no">
          <input
            type="number"
            className="form-control custPhone"
            placeholder="contact no"
            onChange={(e) => setCustContact(e.target.value)}

          />
        </div>
        <div className="message">
          <textarea
            type="text"
            className="form-control custMessage"
            placeholder="message"
            defaultValue={""}
            onChange={(e) => setCustMessage(e.target.value)}

          />
        </div>
        {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button className="form-submit-button" type="submit" onClick={ContactEnquiry}>Submit</button>
        </form>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default ContactMap
