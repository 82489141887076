
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import {Autoplay, Pagination } from 'swiper/modules';



import './Service.css'
import img1 from "../../assets/imageWebp/wynd(7).webp";
import img2 from "../../assets/imageWebp/wynd(4).webp";
import img4 from "../../assets/imageWebp/wynd(2).webp";
import img3 from "../../assets/imageWebp/wynd(6).webp";
import banner from "../../assets/banner1.webp";
import { Link } from 'react-router-dom';
function Service() {
  return (
    <>
      <section id="rooms" className="pt-14 pb-14" data-bs-theme="dark">
  <div className="container">
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2 textcolor ">Wedding &amp; Services</h2>
      <h4 className="h6 fw-normal text-body-secondary ff-sub text-uppercase ">
        Elevating events with celebrity-style decor and service.
      </h4>
    </div>


    <Swiper
        slidesPerView={3}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '@1.00': {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          '@1.50': {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper"
      >
        
        <SwiperSlide style={{ width: 453, marginRight: 30 }}>
        <div
                  className="swiper-slide swiper-slide-active"
                  
                  data-swiper-slide-index={3}
                >
                  {" "}
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img4}
                        // srcSet="/static/media/p4.9a6e49e1ac729603474f.jpg"
                        className="card-img"
                        alt="best-event-management-company-andheri"
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h4
                          className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor"
                          style={{ color: "white" }}
                        >
                          ENTERTAINMENT
                        </h4>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          ENTERTAINMENT
                        </p>
                        <div className="card-link d-flex align-items-center">
                         <Link to="/event-management-near-andheri">
                         <button
                            role="button"
                            tabIndex={0}
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          </Link>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </SwiperSlide>
        <SwiperSlide style={{ width: 453, marginRight: 30 }}>
        <div
                  className="swiper-slide swiper-slide-prev"
                  
                  data-swiper-slide-index={2}
                >
                  {" "}
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img2}
                        // srcSet="/static/media/p3.7a64bffc1c9318b6bdef.jpg"
                        className="card-img"
                        alt="management-company-andheri"
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h4 className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor">
                          PLANNING AND COORDINATION
                        </h4>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          {" "}
                          PLANNING AND COORDINATION
                        </p>
                        <div className="card-link d-flex align-items-center">
                        <Link to="/event-management-near-andheri">
                         <button
                            role="button"
                            tabIndex={0}
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          </Link>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </SwiperSlide>
        <SwiperSlide  style={{ width: 453, marginRight: 30 }}>
        <div
                  className="swiper-slide swiper-slide-next"
                 
                  data-swiper-slide-index={0}
                >
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img1}
                        // srcSet="/static/media/p1.4728f4b23a266038cd28.jpg"
                        className="card-img"
                        alt="event-management-company-andheri"
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h5 className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor">
                          VENUE BOOKINGS
                        </h5>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          VENUE BOOKINGS
                        </p>
                        <div className="card-link d-flex align-items-center">
                        <Link to="/contectus">
                         <button
                            role="button"
                            tabIndex={0}
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </SwiperSlide>
        <SwiperSlide style={{ width: 453, marginRight: 30 }}><div
                  className="swiper-slide"
                  
                  data-swiper-slide-index={1}
                >
                  {" "}
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img3}
                        // srcSet="/static/media/p2.0a9dd2491ccb0cb7ec02.jpg"
                        className="card-img"
                        alt="best-management-"
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h4 className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor">
                          DESIGN AND CONCEPT
                        </h4>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          DESIGN AND CONCEPT
                        </p>
                        <div className="card-link d-flex align-items-center">
                        <Link to="/contectus">
                         <button
                            role="button"
                            tabIndex={0}
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          </Link>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
      </Swiper>
    <div className="tns-outer" id="tns1-ow">
      <div
        className="tns-controls"
        aria-label="Carousel Navigation"
        tabIndex={0}
      />
      <div id="tns1-mw" className="tns-ovh">
        <div className="tns-inner" id="tns1-iw">
          <div
            className="room-luxury-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal"
            data-cue="fadeIn"
            id="tns1"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both"
            }}
          >
            <div className="swiper swiper-initialized swiper-horizontal mySwiper swiper-backface-hidden">
              <div
                className="swiper-wrapper"
                style={{
                  transitionDuration: "0ms",
                  transform: "translate3d(-483px, 0px, 0px)",
                  transitionDelay: "0ms"
                }}
              >
                {/* <div
                  className="swiper-slide swiper-slide-prev"
                  style={{ width: 453, marginRight: 30 }}
                  data-swiper-slide-index={2}
                >
                  {" "}
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={imp2}
                        // srcSet="/static/media/p3.7a64bffc1c9318b6bdef.jpg"
                        className="card-img"
                        alt=""
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h4 className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor">
                          PLANNING AND COORDINATION
                        </h4>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          {" "}
                          PLANNING AND COORDINATION
                        </p>
                        <div className="card-link d-flex align-items-center">
                          <button
                            role="button"
                            tabIndex={0}
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className="swiper-slide swiper-slide-active"
                  style={{ width: 453, marginRight: 30 }}
                  data-swiper-slide-index={3}
                >
                  {" "}
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img1}
                        // srcSet="/static/media/p4.9a6e49e1ac729603474f.jpg"
                        className="card-img"
                        alt=""
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h4
                          className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor"
                          style={{ color: "white" }}
                        >
                          ENTERTAINMENT
                        </h4>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          ENTERTAINMENT
                        </p>
                        <div className="card-link d-flex align-items-center">
                          <button
                            role="button"
                            tabIndex={0}
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className="swiper-slide swiper-slide-next"
                  style={{ width: 453, marginRight: 30 }}
                  data-swiper-slide-index={0}
                >
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img1}
                        // srcSet="/static/media/p1.4728f4b23a266038cd28.jpg"
                        className="card-img"
                        alt=""
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h5 className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor">
                          VENUE BOOKINGS
                        </h5>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          VENUE BOOKINGS
                        </p>
                        <div className="card-link d-flex align-items-center">
                          <button
                            role="button"
                            tabIndex={0}
                            href="#!"
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className="swiper-slide"
                  style={{ width: 453, marginRight: 30 }}
                  data-swiper-slide-index={1}
                >
                  {" "}
                  <div
                    className="mb-5 tns-item tns-slide-active"
                    id="tns1-item0"
                  >
                    <div className="card card-overlay-slide text-bg-dark border-0">
                      <img
                        src={img1}
                        // srcSet="/static/media/p2.0a9dd2491ccb0cb7ec02.jpg"
                        className="card-img"
                        alt=""
                      />
                      <div className="card-img-overlay">
                        <span className="d-block mb-2" />
                        <h4 className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor">
                          DESIGN AND CONCEPT
                        </h4>
                        <p className="textcolor" style={{ fontSize: 15 }}>
                          DESIGN AND CONCEPT
                        </p>
                        <div className="card-link d-flex align-items-center">
                          <button
                            role="button"
                            tabIndex={0}
                            href="#!"
                            className="custom-btn "
                          >
                            enquiry now
                          </button>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Service
