import React,{useLayoutEffect} from 'react'
import "../Footer/Footer.css";
import {Link} from "react-router-dom";
import Logo from "..//../assets/wlogo.png";
const Footer = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-lg-5 col-md-6  mb-30">
          <div className="single-cta">
            <i className="fa fa-map-marker" />
            <div className="cta-text pt-2">
              <h4>Find us</h4>
              <span>SHAH INDUSTRIAL ESTATE CABIN, B/2, Saki Vihar Rd, Andheri East, Mumbai, Maharashtra 400072
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="single-cta">
            <i className="fa fa-phone" />
            <div className="cta-text pt-2">
              <h4>Call us</h4>
              <span>     +91 91676 38955
            <br />
            +91 70213 76022</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 mb-30">
          <div className="single-cta">
            <i className="fa fa-envelope-open" />
            <div className="cta-text pt-2">
              <h4>Mail us</h4>
              <span>Wyndbanquet@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="/">
                <img
                  src={Logo}
                  className="img"
                  alt="logo"
                />
              </a>
            </div>
            <div className="footer-text">
              <p>
            
              </p>
            </div>
                <div className="footer-widget">
            {/* <div className="footer-widget-heading">
              <h3></h3>
            </div> */}
          
          
          </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul className="m-0 p-0">
              <li>
                <Link to="/">Home</Link>
              </li>
             
             
           
              <li>
                <Link to="/aboutus">About Us</Link>
              </li>
             
              <li>
                <Link to="/Banquet">Banquet</Link>
              </li>
              {/* <li>
                <Link to="/best-agro-tourism-resort-neral">Rooms</Link>
              </li> */}


              <li>
                <Link to="/Gallery">Gallery</Link>
              </li>
              
              <li>
                <Link to="/contectus">Contact Us </Link>
              </li>
            </ul>
          </div>
      
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 ">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Address</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              SHAH INDUSTRIAL ESTATE CABIN,<br/> B/2, Saki Vihar Rd, Maharashtra Nagar,<br/> Raje Shivaji Nagar, Marol, Andheri East, Mumbai, Maharashtra 400072
              </p>
            </div>
            {/* <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="#">
                <i className="fa fa-facebook-f facebook-bg" />
              </a>
              <a href="#">
                <i className="fa fa-twitter twitter-bg" />
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div> */}
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="">
        <div className="">
          <div className="copyright-text  justify-content-space-between">
            <p>
              Copyright © 2024, All Right Reserved{" "}
              <br/>
              <a href="">Wynd Events </a>
            </p>
            <p>
              Developed By &nbsp;
              <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
            </p>
          </div>
        </div>
      
        </div>
      </div>
    </div>
  

</footer>

<div className="floattingButton">
  {/* <a href="#" className="float" id="menu-share">
    <i className="fa fa-link my-float" />
  </a> */}
  <ul>
    <li>
      <a href="https://www.instagram.com/wyndeventplanner/" target='_blank'>
        <i className="fa fa-instagram my-float" />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/wyndeventplanner" target='_blank'>
        <i className="fa fa-facebook my-float" />
      </a>
    </li>
  </ul>
</div>
    </div>
  )
}

export default Footer