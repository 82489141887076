import React from 'react'
import "./csss.css"
import chair from "../../assets/imageWebp/wynd(3).webp"
import chair2 from "../../assets/imageWebp/wynd(6).webp"
import { Link } from 'react-router-dom'
// import Fade from 'react-reveal/Fade';

function WelcomeAboutUs() {
  return (
<section id="about" className="pt-14 pb-14">
  <div className="container">
    <div
      className="row align-items-start align-items-md-stretch"
      data-cues="fadeIn"
      data-disabled="true"
    >
      <div
        className="col-12 col-lg-6 order-1 order-lg-0"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal row g-0"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-1"
          }}
        >
          <div className="col-lg-6 col-12 order-md-1 order-2">
            <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
            
            {/* <Fade left>  */}
            <img
                src={chair}
               
                className="img-fluid w-100 rounded shadow-sm"
                alt="best-event-management"
              />
              {/* </Fade> */}
            </figure>
          </div>
          <div className="col-lg-6 col-12 order-md-1 order-2">
            <figure className="mb-0 pt-9">
            {/* <Fade left>  */}
              <img
                src={chair2}
               
                className="img-fluid w-100 rounded shadow-sm "
                alt="management-company-andheri"
              />
              {/* </Fade> */}
            </figure>
          </div>
        </div>
      </div>
      <div
        className="col-12 col-lg-6 order-0 order-lg-1 order-md-2 order-1"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal bg-white h-100 d-flex align-items-center"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-2"
          }}
        >
          <div className="p-lg-5 mb-8">
            {/* <Fade left > */}
            <span className="mb-2 mt-3 fw-medium text-secondary ff-sub text-uppercase ls-1 d-block">
              About
            </span>
            <h2 className=" ff-heading">
              Wynd  Events
              </h2>
            <p className="mb-6">
            Welcome to Wynd banquet , a corporate event organisers in Andheri, where luxury meets affordability in the heart of the economic spectrum. With a generous capacity to accommodate up to 2000 guests, our venues redefine opulence in the bustling metropolis.
            Situated in the pulsating hub of Mumbai, our banquet halls are a testament to grandeur and financial sensibility coexisting seamlessly. corporate event organisers in Andheri understands the diverse needs of our clientele, offering an unparalleled blend of sophistication and budget-consciousness for every special occasion.

          <p className='d-none'>
          corporate event organisers in Andheri, Our meticulously designed spaces are crafted to reflect the dynamic spirit of Mumbai while providing a backdrop for events that demand both extravagance and financial prudence. Whether it's a dazzling wedding ceremony or a corporate extravaganza, our venues set the stage for unforgettable moments.

What distinguishes us is our commitment to delivering a luxurious experience without compromising on economic considerations. From the modern decor to cutting-edge facilities, every aspect of the corporate event organisers in Andheri is tailored to enhance your event experience.

In the city that never sleeps, we stand as a beacon of luxury in the economy segment, offering a haven where affordability intertwines seamlessly with grandeur.In our corporate event organisers in Andheri , Our dedicated team ensures that your event transcends expectations, delivering an unmatched experience that reflects the spirit of Mumbai's vibrant celebrations.

Welcome to a world where the allure of luxury and the practicality of the economy converge to create an unparalleled setting for your celebrations. Explore the perfect synergy of indulgence and fiscal prudence at our banquet halls in the heart of Mumbai.

          </p>
            </p>
            {/* <a role="button" tabIndex={0} href="#!" className="custom-btn "> 
            <a href="/wedding-event-planner-mumbai" style={{ color: "white" }}>
              <Link to='/Gallery'> Read More</Link>
             
            </a>
            </a> */}
            {/* </Fade> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  )
}

export default WelcomeAboutUs
