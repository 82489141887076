
import { FaLocationPin } from "react-icons/fa6";
import React, {  useLayoutEffect } from 'react'
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import Footer from '../Footer/Footer'

import "./Contact.css"
import BannerContectUs from "../BannerContect-us/BannerContectUs";
import ContactMap from "../ContactMap.jsx/ContactMap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from 'jquery';

function Contact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <>
       <BannerContectUs/>
    <section className="contact-info">
  <div className="container">
    <p className="d-none">
    Connect with an event management company near Andheri,effortlessly at our banquet hall, where your celebrations find a home filled with sophistication and warmth. Our event management company near Andheri is here to assist you in crafting memorable events, be it weddings, corporate functions, or special gatherings.

For inquiries, bookings, or to schedule a visit, event management company near Andheri contact details are readily available. Reach out to us via phone at [Your Phone Number] or drop us an email at [Your Email Address]. We value open communication and are committed to responding promptly to your queries.

Visit us at [Your Address] to experience the charm of our banquet hall firsthand. Whether you have specific event requirements or simply wish to explore the possibilities, our team is ready to guide you through the process.

Follow us on social media [Provide Social Media Handles] for the latest updates, event inspirations, and behind-the-scenes glimpses of our venue. We look forward to being part of your special occasions and ensuring that your celebrations at the event management company near Andheri  are nothing short of extraordinary.

    </p>
    <div className="row">
      <div className="col-lg-5 col-md-6 col-sm-12 column">
        <div className="single-item">
          <div className="icon-box">
          <FaLocationPin  style={{ height: '50px', width: '50px'}} />
          </div>
          <div className="text" >
        
           B-201, 2nd Floor,    Shah Ind. Estate,
         
          <br/>Saki Vihar Road,Andheri East,Mumbai,
        
          <br />  Maharashtra - 400072.
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 column">
        <div className="single-item">
          <div className="icon-box">
          <ImMobile  style={{ height: '50px', width: '50px' }}  />
          </div>
          <div className="text" >
            +91 91676 38955
            <br />
            +91 70213 76022
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 column">
        <div className="single-item">
          <div className="icon-box">
          <MdEmail style={{ height: '50px', width: '50px' }}  />

          </div>
          <div className="text">
          wyndhotels@gmail.com
            <br />
            reservation@thewyndhotels.com
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ContactMap/>

 
    
    <Footer/>
    </>
  )
}

export default Contact
