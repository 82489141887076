import React from 'react'
import "./csss.css"
import chair from "../../assets/imageWebp/wynd(9).jpg"
import chair2 from "../../assets/imageWebp/wynd(8).jpg"
import { Link } from 'react-router-dom'
// import Fade from 'react-reveal/Fade';

function WelcomeBanquet() {
  return (
<section id="about" className="pt-14 pb-14">
  <div className="container">
    <div
      className="row align-items-start align-items-md-stretch"
      data-cues="fadeIn"
      data-disabled="true"
    >
      <div
        className="col-12 col-lg-6 order-1 order-lg-0"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal row g-0"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-1"
          }}
        >
          <div className="col-lg-6 col-12 order-md-1 order-2">
            <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
            
            {/* <Fade left>  */}
            <img
                src={chair}
               
                className="img-fluid w-100 rounded shadow-sm"
                alt="best-event-management-company-andheri"
              />
              {/* </Fade> */}
            </figure>
          </div>
          <div className="col-lg-6 col-12 order-md-1 order-2">
            <figure className="mb-0 pt-9">
            {/* <Fade left>  */}
              <img
                src={chair2}
               
                className="img-fluid w-100 rounded shadow-sm "
                alt="best-event"
              />
              {/* </Fade> */}
            </figure>
          </div>
        </div>
      </div>
      <div
        className="col-12 col-lg-6 order-0 order-lg-1 order-md-2 order-1"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal bg-white h-100 d-flex align-items-center"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-2"
          }}
        >
          <div className="p-lg-5 mb-8">
            {/* <Fade left > */}
            <span className="mb-2 mt-3 fw-medium text-secondary ff-sub text-uppercase ls-1 d-block">
              Welcome to
            </span>
            <h2 className=" ff-heading">
              Wynd  Banquet
              </h2>
            <p className="mb-6">
            Step into a world of refined celebrations at our Wynd event, a best banquet hall in Andheri. With a commitment to excellence, our venue stands as a testament to sophistication and versatility, offering an unparalleled space for a spectrum of events.

Our best banquet hall in Andheri, designed to accommodate diverse occasions, becomes the canvas for your dreams, whether it's a wedding, corporate event, birthday party, or any special gathering. With a capacious setting, we pride ourselves on providing a space that effortlessly combines opulence with functionality, ensuring that every event hosted here is a masterpiece.


          <p className='d-none'>
          Immerse yourself in an ambiance meticulously crafted to evoke a sense of grandeur. Our best banquet hall in Andheri boasts tasteful decor, spacious interiors, and state-of-the-art facilities to elevate your celebrations. From intimate gatherings to large-scale events, our versatile space adapts to your vision, creating an atmosphere that resonates with the essence of your occasion.

          What sets us apart is our unwavering commitment to detail and personalized service. In the best banquet hall in Andheri, Our dedicated team works closely with clients, ensuring that every aspect of the event is tailored to perfection. Whether it's the layout, decor, or technological requirements, we strive to exceed expectations and create a seamless experience for hosts and guests alike.

Explore our gallery, a visual testament to the diverse celebrations that have graced our banquet hall. Each image captures the essence of moments filled with joy, love, and success – a reflection of the countless stories that have unfolded within our walls.

Welcome to the best banquet hall in Andheri where celebrations transcend ordinary to extraordinary. Join us in creating memories that linger long after the last guest departs. Your special moments find a perfect home in our banquet hall, where every detail is a brushstroke on the canvas of your unforgettable events. Elevate your celebrations with us – where sophistication meets celebration, and your vision takes center stage.

          </p>
            </p>
            {/* <Link to='/aboutus'>
            <a role="button" tabIndex={0} href="#!" className="custom-btn "> 
            <a href="/wedding-event-planner-mumbai" style={{ color: "white" }}>
             Read More
             
            </a>
            </a>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  )
}

export default WelcomeBanquet
