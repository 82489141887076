import React from 'react'

function GalleryPoster() {
  return (
    <div>
      {/* <section
  className="page-title centred"
  style={{ backgroundImage: "url(images/background/page-title.jpg)" }}
>
  <div className="container">
    <div className="content-box">
      <div className="title">Gallery</div>
      <ul className="bread-crumb">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>Gallery</li>
      </ul>
    </div>
  </div>
</section> */}

    </div>
  )
}

export default GalleryPoster
