import birthday from "../../assets/imageWebp/birthday.jpeg";

import corporate1 from "../../assets/imageWebp/speaker-business-conference-presentation-audience-600nw-217119211.webp";

import wedding2 from "../../assets/wedding2.webp";
const EventData =[
    {
        id:1,
        slug: "/top-corporate-event-management-companies-andheri",
        h1:"Corporate",
        eventName:"Corporate Event",
        greet:"Welcome To corporate",
        Header:"Wynd Banquet",
        Content:'Welcome to our Wynd event , a top event organisers in Andheri ,where the essence of corporate sophistication converges with seamless event execution. As a premier venue for corporate events, top event organisers in Andheri offer a setting that transcends expectations, blending functionality with refined aesthetics.Our banquet hall, with a capacity for hosting corporate gatherings, conferences, and seminars, stands as a beacon of professionalism. From high-profile meetings to product launches, our venue provides a versatile canvas tailored to meet the unique demands of corporate events.Immerse yourself in an environment where state-of-the-art facilities harmonize with tasteful decor. Top event organisers in Andheri, Our commitment to excellence is evident in every detail, ensuring a conducive atmosphere for productive discussions and networking opportunities.',
        seoPara:"Our top event organisers in Andheri understand the intricacies of corporate events, working closely with clients to tailor the space to their specific needs. Whether it's advanced audiovisual setups, customizable seating arrangements, or gourmet catering services,top event organisers in Andheri prioritize precision to elevate your corporate functions.Explore our gallery showcasing past corporate events, offering a glimpse into the seamless integration of professionalism and luxury. Witness the versatility of our banquet hall as it transforms to accommodate a spectrum of corporate occasions, each radiating an air of success and accomplishment.Beyond being a space, we are facilitators of corporate milestones. As a top event organisers in Andheri, Our commitment extends beyond providing a venue; it encompasses curating an environment where your brand shines. Join us in creating impactful corporate events that leave a lasting impression, where your vision aligns seamlessly with our commitment to excellence.Welcome to a venue where corporate sophistication meets impeccable execution. Elevate your business events in a banquet hall designed to inspire, impress, and foster success. We invite you to discover the perfect synergy of professionalism and luxury for your next corporate endeavor.",
        imageRight:corporate1,
        title:"Top Corporate Event Management Companies in Andheri",
        discription:"Explore the Top  Corporate Event Management Companies in Andheri. Discover expert event planners  & organizers who can elevate your corporate events."

    },
    {
        id:2,
        slug: "/wedding-planners-andheri",
        h1:"Weddding",
        eventName:"Weddding Event",
        greet:"Welcome To Wedding",
        Header:"Wynd Banquet",
        Content:"Step into a world of timeless romance and unparalleled elegance as we welcome you to our Wynd event , a top event organisers in Andheri – the epitome of dream weddings. With a capacity to host the most magical celebrations, our venue is a canvas where love stories unfold in grandeur and sophistication.At the heart of our commitment is the belief that weddings are not just events; they are the culmination of dreams and the beginning of beautiful journeys. Our banquet hall, meticulously designed for such momentous occasions, is a testament to the perfect blend of opulence and intimate charm.As you embark on your journey through our venue, discover the artistry in every detail – from the ethereal decor to the spacious interiors that can accommodate your closest loved ones. With a capacity to host large gatherings, top event organisers in Andheri cater to the grandest celebrations, ensuring that each moment is infused with grace and joy.",
        seoPara:"Our top event organisers in Andheri of wedding specialists are dedicated to turning your vision into reality. We understand that every wedding is unique, and our commitment is to craft an experience that reflects your individual style and personality. Whether it's a traditional ceremony or a contemporary affair, our venue transforms to suit your desires.Explore our gallery, a visual narrative of love stories that have unfolded within our walls. From the exchange of vows to the joyous celebrations, each photograph encapsulates the magic that defines weddings at our banquet hall. Welcome to a venue where weddings are not just events; they are enchanting tales woven into the fabric of our banquet hall. Join us in creating memories that last a lifetime, where the union of love and luxury forms the perfect backdrop for your extraordinary day. Your dream wedding awaits in our captivating space, where every detail is meticulously curated to make your celebration truly unforgettable.",
        imageRight:wedding2,
        title:"Wedding Planners in andheri | WYND EVENTS",
        discription:"Discover exceptional Wedding Planners in Andheri to make your special day unforgettable. Explore expert services and personalized solutions ."
    },
    {
        id:3,
        slug: "/birthday-party-organisers-andheri",
        h1:"Birthday",
        eventName:"Birthday Event",
        greet:"Welcome To Birthday",
        Header:"Wynd Banquet",
        Content:"Welcome to our Wynd event,a top event organisers in Andheri , where the joy of celebrations takes center stage, and birthdays become unforgettable moments of laughter and merriment. With an ambiance designed to infuse festivities with flair, our venue is the perfect canvas for creating memories that last a lifetime.At the heart of our commitment is the understanding that birthdays are milestones meant to be celebrated in style. Our banquet hall, with its capacity to host lively gatherings, transforms into a haven of joy where every detail is crafted to make your birthday party an extraordinary affair. Step into an environment where vibrant decor meets versatile spaces, catering to themed parties, intimate gatherings, or grand celebrations. From the moment you enter, you'll sense the air of excitement and anticipation, as top event organisers in Andheri work tirelessly to ensure your vision for the perfect birthday comes to life.",
        seoPara:"Our top event organisers in Andheri understand the importance of personalization. Whether it's a surprise element, a specific theme, or a favorite color palette, we collaborate with you to customize the venue to reflect your unique style. Our goal is to make your birthday celebration as special and distinctive as you are.Explore our gallery, a visual journey capturing the energy and happiness that have filled our banquet hall during countless birthday celebrations. Each photograph tells a story of laughter, joy, and the special moments that make birthdays truly magical. Welcome to a venue where birthdays are not just occasions; they are joyous experiences wrapped in the warmth of our banquet hall. Join our top event organisers in Andheri in creating memories that mark the passage of time with smiles and laughter. Your extraordinary birthday celebration begins here, where every detail is a celebration of you and the joyous moments that make birthdays truly special.",
        imageRight:birthday,
        title:"Birthday Party Organisers in Andheri | WYND EVENTS",
        discription:"Find the best Birthday Party Organisers in Andheri to make your celebration extraordinary. Explore expert event planners who will make your birthday special."
    }
]
export default EventData