import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination,Autoplay } from 'swiper/modules';
import "./Testimonial2.css";
const Testimonial2 = () => {
  return (
    <div>

<section id="facilitiesAmenities" className="pt-14 pb-10">
  <div className="container">
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2">Testimonial</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
        Dream events, flawlessly executed.
      </p>
    </div>
    <div>
      <div
        className="swiper swiper-initialized swiper-horizontal mySwiper1"
        style={{ height: 200 }}
      >
        <div className="swiper-wrapper">


        <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
      >
        <SwiperSlide>
        <div
            className="swiper-slide swiper-slide-active "
            id="style-4"
          >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Nice banquet hall
Parking space available
Hall is on 2nd floor but elevator is available
Hall can accomodate 500 to 800 peoples"{" "}
                </blockquote>
                <div className="author">
                  <h5>Sarfaraz Lambe</h5>
                </div>
              </figure>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div
            className="swiper-slide swiper-slide-next"
            
          >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "The venue was absolutely stunning, and the decorations were perfect in every way. The attention that you paid to every detail helped to create a warm and welcoming atmosphere that set the tone for the entire event. From the exquisite floral arrangements to the elegant table settings and everything in between, the décor was simply breathtaking, and I couldn't have been happier with the way that everything turned out."
                </blockquote>
                <div className="author">
                  <h5>Sweta Ranganathan
</h5>
                </div>
              </figure>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide" >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Amazing banquet for different kind of functions to do...well managed and amazing staff and system..."
                </blockquote>
                <div className="author">
                  <h5>Fardin Khan</h5>
                </div>
              </figure>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide" >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "The place is very beautiful. One can hold any occasion there, it offers beautiful hall with perfectly cleaned washroom and good air-conditioned area. The lift is also working in great condition. Definately recommend to anyone who want to hold large or small gathering there."
                </blockquote>
                <div className="author">
                  <h5>
insanegirl</h5>
                </div>
              </figure>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-slide" >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "A good and decent place to have your functions. They have a big banquet hall with indoor and outdoor areas. There is limited parking in the compound but there is some space outside. The staff and service is good."
                </blockquote>
                <div className="author">
                  <h5>Adrian Rodrigues</h5>
                </div>
              </figure>
            </div>
          </div>
        </SwiperSlide>
       
      </Swiper>






























          {/* <div
            className="swiper-slide swiper-slide-active"
            style={{ width: 412, marginRight: 30 }}
          >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                   Accusantium doloribus atque facere sit, necessitatibus quo!"{" "}
                </blockquote>
                <div className="author">
                  <h5>reena sani</h5>
                </div>
              </figure>
            </div>
          </div> */}
          {/* <div
            className="swiper-slide swiper-slide-next"
            style={{ width: 412, marginRight: 30 }}
          >
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                   Accusantium doloribus atque facere sit, necessitatibus quo!."
                </blockquote>
                <div className="author">
                  <h5>Rahul Mahto</h5>
                </div>
              </figure>
            </div>
          </div> */}
          {/* <div className="swiper-slide" style={{ width: 412, marginRight: 30 }}>
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                   Accusantium doloribus atque facere sit, necessitatibus quo!"
                </blockquote>
                <div className="author">
                  <h5>Mina Soti</h5>
                </div>
              </figure>
            </div>
          </div> */}
          {/* <div className="swiper-slide" style={{ width: 412, marginRight: 30 }}>
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                   Accusantium doloribus atque facere sit, necessitatibus quo!"
                </blockquote>
                <div className="author">
                  <h5>puran patel</h5>
                </div>
              </figure>
            </div>
          </div> */}
          {/* <div className="swiper-slide" style={{ width: 412, marginRight: 30 }}>
            <div className="testimonial-content"  id="style-4">
              <figure className="snip1192">
                <blockquote>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                   Accusantium doloribus atque facere sit, necessitatibus quo!"
                </blockquote>
                <div className="author">
                  <h5>Roma singh</h5>
                </div>
              </figure>
            </div>
          </div> */}
        </div>
        <div className="swiper-pagination" />
      </div>
    </div>
  </div>
</section>


    </div>
  );
};

export default Testimonial2;
