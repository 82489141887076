import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import "swiper/css";
import "swiper/css/pagination";
import "./Banner.css";
import { Link } from "react-router-dom";
import { Pagination, Navigation } from "swiper/modules";
import swipperImg1 from '../../assets/img12.webp'
import swipperImg2 from '../../assets/jpeg-optimizer_img5.webp'
import swipperImg3 from '../../assets/jpeg-optimizer_img9.webp'
import banner1 from '../../assets/imageWebp/wynd(5).webp'
import banner2 from "../../assets/imageWebp/wynd(10).webp";
import banner3 from "../../assets/imageWebp/wynd(4).webp";
import "../Service/Service.css"
const Banner = () => {
  return (
   <>
   {/* <div
  className="item bannerIMAGES1 image-banner"
  style={{
    backgroundImage: `url(${banner1})`
  }}
> */}

<Swiper navigation={true} modules={[Navigation]}className="mySwiper">
<SwiperSlide>
          <div className="swiper2 imagee slider-banner first-banner"
          // style={{
          //   backgroundImage: `url(${banner3})`
          // }}
          
          >
          <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi banner-title">
          Elegance Redefined: Your Perfect Celebration Awaits
                    </h1>
          <p className="textcenter colorWhi">
          Craft memories in venues echoing timeless elegance and everlasting allure.
          </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href="/wedding-event-planner-mumbai"
                style={{ color: "white" }}
              >
                 <Link to="social-event-organisers-andheri">
                 View More
                </Link>
             
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
          </div>
          </div>
         
  </SwiperSlide>
{/* <SwiperSlide>
  <div className="imagee slider-banner first-banner"
   style={{
    backgroundImage: `url(${banner1})`,
  }}
  >
    <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi">
          Elegance Redefined: Your Perfect Celebration Awaits
          </h1>
          <p className="textcenter colorWhi">
          Craft memories in venues echoing timeless elegance and everlasting allure.    </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href=""
                style={{ color: "white" }}
              >
                <Link to="Gallery">
                View More
                </Link>
              
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </div>
  </SwiperSlide> */}
      
        <SwiperSlide> 
          <div className="swiper2 imagee slider-banner second-banner"
        
          >
           <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi banner-title">
          From Dreams to Reality: Transforming Events in Our Opulent Banquet Hall.          </h1>
          <p className="textcenter colorWhi">
          Witness spaces evolve into personalized dreamscapes with seamless creativity.
          </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href="/wedding-event-planner-mumbai"
                style={{ color: "white" }}
              >
                <Link to='Gallery'>
                View More
                </Link>
              
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
         </div>
          </div>
  </SwiperSlide>

        <SwiperSlide>
          <div className="swiper2 imagee slider-banner third-banner"
          // style={{
          //   backgroundImage: `url(${banner3})`
          // }}
          
          >
          <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi banner-title">
          Beyond Expectations: Your Extraordinary Moments Begin in Our Banquet Hall
                    </h1>
          <p className="textcenter colorWhi">
            Event management Crafting seamless, unforgettable experiences for
            you
          </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href="/wedding-event-planner-mumbai"
                style={{ color: "white" }}
              >
                 <Link to="Gallery">
                 View More
                </Link>
             
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
          </div>
          </div>
         
  </SwiperSlide>
      </Swiper>
{/* </div> */}

   </>

  );
};

export default Banner;
